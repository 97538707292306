// /* You can add global styles to this file, and also import other style files */
// @import "./colors.scss";

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Americana std';
  src: url('./assets/fonts/americanastd.ttf');
}

html * {
  font-family: Poppins;
}

body {
  font-weight: 300;
  overflow-y: visible !important;
}

.ant-btn,
button {
  border-radius: 22px;
  border: 1px solid #000000;
  height: 46px;
  > span {
    font-size: 16px;
    font-weight: 400;
  }
}

.ant-checkbox .ant-checkbox-inner {
  width: 23px;
  height: 23px;
  background-color: white;
  border: 4px solid white !important;
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  width: 23px;
  height: 23px;
  background-color: #aa9dfa;
  border: 4px solid white;
  border-radius: 4px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  opacity: 0;
}

.sub-content-options {
  display: flex;
  width: 100%;
  > div:first-child {
    width: 70%;
    > p {
      line-height: 19px;
    }
  }
  > div:nth-child(2) {
    width: 30%;
    text-align: right;
    > p {
      width: fit-content;
      border-bottom: 0.5px solid #000;
      padding-bottom: 7px;
      cursor: pointer;
      float: right;
      font-size: 16px;
    }
    > p:hover {
      border-bottom: none;
    }
  }
}

bemum-personal-information {
  height: fit-content;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-checked:after {
  border-color: white;
}

.ant-checkbox-input:hover {
  outline: none !important;
  border-color: none !important;
  border: 1px solid transparent !important;
}

.ant-layout,
body {
  background: url('./assets/images/bemum_background.png');
  background-repeat: no-repeat;
  background-position: top right;
  background-attachment: fixed;
}

.title-page {
  font-family: Americana Std;
  font-size: 77px;
}

.label {
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
}

.ant-input,
input {
  height: 47px;
  border-radius: 22px !important;
  border: 0.5px solid #d6d6d6;
  font-size: 16px;
  font-weight: 300;
  color: #636872 !important;
  padding-left: 20px;
  background: transparent !important;
}

.ant-input:focus,
input:focus,
.ant-input:hover,
input:hover {
  outline: none !important;
  border-color: #d6d6d6;
}

.bemum-purple {
  background: #b3a3ff;
  border: none;
  color: white;
}

.bemum-purple:hover {
  background: #9e8aff;
  color: white;
}

.card-content {
  padding-top: 31px;
  padding-right: 21px;
  padding-left: 21px;
  padding-bottom: 39px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.smart-link {
  font-size: 12px;
  float: right;
  text-decoration: underline;
  color: black;
  font-weight: 300;
}

.smart-link:hover {
  color: inherit !important;
  text-decoration: none;
}

.header {
  height: unset;
  padding: 0 96px;

  > .nav-content {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  background-color: white;

  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);

  .logo {
    background-image: url('./assets/svgs/logo BeMum.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.home-link {
  text-align: center;
  > a {
    font-weight: 400;
    font-size: 1.5em;
    text-decoration: none;
    color: black;
  }
}

.error {
  color: red;
}

.button-sub-options:hover {
  background-color: black;
  color: white;
  border-color: black;
}

// low mobile
@media screen and (max-width: 414px) {
  bemum-personal-information {
    height: fit-content;
  }

  .title-page {
    font-size: 3.5em;
  }
  .ant-layout,
  body {
    background: url('./assets/images/bemum_background_mobile.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    background-attachment: fixed;
    background-size: 84%;
  }

  .logo {
    background-image: url('./assets/svgs/bemum_mobile.svg') !important;
    position: relative;
    left: unset;
  }
}

// mobile mode
@media screen and (min-width: 414px) and (max-width: 767px) {
  .ant-btn,
  button {
    min-width: 132px;
    margin-right: 10px;
  }

  .ant-layout,
  body {
    background: url('./assets/images/bemum_background_mobile.png') !important;
    background-repeat: no-repeat !important;
    background-position: bottom right !important;
    background-attachment: fixed !important;
  }

  .header {
    padding: 0px !important;
  }

  .logo {
    background-image: url('./assets/svgs/bemum_mobile.svg') !important;
    position: relative;
    left: 16px;
  }

  .title-page {
    font-size: 40px !important;
  }

  .vertical-center {
    margin: 0 17px !important;
    margin-top: 41px !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
  }

  .card-content {
    padding-right: 11px !important;
    padding-left: 11px !important;
  }

  .ant-btn > span,
  button > span {
    font-size: 1em !important;
  }

  bemum-personal-information {
    height: fit-content;
  }
}

// tabet mode
@media screen and (min-width: 768px) and (max-width: 1069px) {
  .vertical-center {
    margin: 0 17px !important;
    margin-top: 95px !important;
    position: relative !important;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    width: 452px;
  }

  .ant-layout,
  body {
    background: url('./assets/images/bemumu_background_tablet.png') !important;
    background-repeat: no-repeat !important;
    background-position: top right !important;
    background-attachment: fixed !important;
  }

  .title-page {
    font-size: 60px !important;
  }

  .smart-link {
    font-size: 12px;
    margin-top: 5px;
  }

  .header {
    padding: 0 13px;
  }

  .ant-btn,
  button {
    min-width: 132px;
  }

  .ant-btn > span,
  button > span {
    font-size: 16px !important;
  }
}

// low poly desktop
@media screen and (min-width: 1070px) and (max-width: 1439px) {
  .ant-layout,
  body {
    background-repeat: no-repeat !important;
    background-position: top right !important;
    background-attachment: fixed !important;
    background-size: 45%;
  }
}

// desktop and more
@media screen and (min-width: 1440px) {
  .ant-btn,
  button {
    min-width: 170px;
  }
}

.widget_colissimo_bouton_validation {
  width: 250px !important;
  height: 44px !important;
}

.widget_colissimo_contenu {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}

.widget_colissimo_input_text_normal {
  border-radius: 0px !important;
}
